/* RestaurantCard.css */
  .restaurant-card {
    position: relative;
    /* width: 18rem; */
    width: 250px; /*remove this if you want cards that change width dynamically */
    margin-bottom: 1rem;
    background-color: #f7f9fb;
    transition: box-shadow 0.7s ease; /* Adding transition */

  }
  
  .restaurant-card .card-img-top {
    height: 200px;
    object-fit: cover;
    user-select: none; /* Prevent selection */
    -webkit-user-drag: none; /* WebKit-specific, prevents dragging */
    pointer-events: none; /* Prevents clicking and dragging */
  }
  
  .restaurant-card .category-badge {
    position: absolute;
    top: 0.5rem; /* Adjust top and right as per your design needs */
    right: 0.5rem;
    border-radius: 5px;
    background-color: #fbb304 !important;;
    color: black;
    padding: 0.6rem;
    font-weight: lighter;
  }
  
  .restaurant-card .btn-website {
    background-color: #000; /* Set the button color you want */
    border: none;
    margin-top: 1rem;
  }
  
  .restaurant-card .card-body {
    padding: 1rem;
  }
  
  .restaurant-card .card-title {
    margin-bottom: 0.5rem;
  }
  
  .restaurant-card .card-text {
    color: #333;
    font-size: 0.9rem;
  }

  .restaurant-card:hover {
    box-shadow: -1px 2px 8px rgb(0 0 0 / 17%)
  }
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 15px; /* Padding to make sure content doesn't touch the edges */
  }
  .search-container {
    width: 100%;
    max-width: 1200px; /* Adjust this to match the max-width of your cards */
    margin: 0 auto; /* This centers the search container */
    padding: 20px 0;
  }
  .address {
    border-bottom: 1px solid #ccc; /* Adding a grey line below the address */
    padding-bottom: 10px; /* Adding some space below the address */
  }
  .name {
    font-weight: bold;
  }


.search-input::placeholder {
  color: #888; /* Placeholder text color */
}

.filter-container button {
    padding: 10px;
    margin: 0 5px;
    border: none;
    background-color: #fbb304;
    color: white;
    border-radius: 5px;
  }
  
  .filter-container button.active {
    background-color: #dca104;
  }

.filter-container {
  flex: 0 1 auto; /* Don't grow, but allow shrink */
  padding: 10px;
  width: 200px; /* Adjust based on your design needs */
}

.filter-container .choose { 
  font-size: 12px;
  color: hwb(0 50% 50%);
  margin-right: 20px;
}
.restaurant-grid {
  flex: 1 1 0; /* Grow and shrink as needed, starting with a basis of 0 */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 8px;
  padding: 0px;
    /* READD this AND TEST IT */
}

.msg {
  font-size: 44px;
  margin: 50px;
}
.search-input {
  width: 78%; /* 15px padding on each side */
  padding: 10px 15px;
  margin-bottom: 20px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 20px;
  font-size: 18px;
  outline: none;
}

.grid-and-filter-container { 
  display: flex;
  flex-direction: row; /* Ensure it's explicitly set to row */
  justify-content: space-between;
  align-items: start;
  width: 80%; /* Ensure the container takes full width */
}
  
.cuisine-dropdown {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin: 10px;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, sans-serif;
}

#btn {
  padding: 5px 10px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #f9f9f9;
  cursor: pointer;
  outline: none;
}

.pagination button:hover {
  background-color: #e9e9e9;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination span {
  margin: 0 10px;
}

#pag-msg {
  margin: 5px;
}

@media (max-width: 880px) {
  .grid-and-filter-container { 
    display: flex;
    flex-direction: column; /* Ensure it's explicitly set to row */
    justify-content: space-between;
    align-items: start;
    max-width: fit-content;
    margin-inline: auto;  
  }
 .filter-container {
  max-width: fit-content;
  margin-inline: auto;  
 }



}

@media (max-width: 550px) {

  .msg{
    font-size: 30px;
  }


}

@media (max-width: 480px) {

  .cuisine-dropdown {
    max-width: fit-content;
    margin-inline: auto;
  }
  #explore-button {
    padding: 5px 19px;
  }

  
  .restaurant-grid {
    grid-template-columns: 1fr; /* One column on very small screens */
    gap: 10px; /* Even smaller gap */
  }

  .restaurant-card {
    /* padding: 8px; Minimal padding */
    font-size: 85%; /* Even smaller text */
    transition: box-shadow 0.3s ease; /* Adding transition */

  }

  .restaurant-card card {
    height: 60%;

  }
}
.footer {
    background-color: #f8f9fa;
    padding: 20px 0;
    text-align: center;
  }
  
  .footer-content {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .email {
    color: #000000;
    text-decoration: none;
    font-weight: bold;
  }
  
  .email:hover {
    text-decoration: underline;
  }
  .call-to-action {
    margin-top: 20px;
    text-align: center;
  }
  
  .call-to-action h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .call-to-action p {
    font-size: 1em;
    margin-bottom: 15px;
  }
  
  .cta-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #ffcc00;
    color: #000;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #e6b800;
  }
  

